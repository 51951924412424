import { Box, Grid, Skeleton, Typography } from "@mui/material"
import { Placeholder } from "../Placeholder/Placeholder"


export default function ImageGrid({ data, isLoading }) {
    return (
        <Box sx={{ flexGrow: 1, marginLeft: 3, marginBottom: 2, minHeight: 20 }}>
            <Grid container spacing={2} columns={data ? 12 / data.length : 1} role="grid">
                {
                    data != null ? data.map((obj, i) => {
                        return (
                            <Grid key={`${i} ${obj.deviceName}`} xs item={true} display="flex" justifyContent="center" alignItems="center">
                                {
                                    isLoading ?
                                        <Skeleton variant="rectangular" width={500} height={240} /> :
                                        obj.image ? <img src={`data:image/jpeg;base64,${obj.image}`} alt={obj.deviceName} style={{ height: "240px", width: "500px" }} /> : <Placeholder backgroundColor="lightGray" height={"240px"} width={"500px"} textColor="gray" text={<span>NO <br/> IMAGE</span>}/>
                                }
                            </Grid>
                        )
                    }) : (
                        < Grid container justifyContent="center" key={"No Image"}>
                            <Typography>No images to display</Typography>
                        </Grid>
                    )
                }
            </Grid>
        </Box>
    )
}