import { QueryFunctionContext, useQueries } from "@tanstack/react-query";
import { DetectionParams } from "../services/TaglessApi/TaglessApiService";
import { Device } from "../types/Device";
import { Deployment } from "../types/Deployment";
import { useApiClient } from "../providers/ApiClientProvider";
import { Detection } from "../types/Detection";
import { generateDetectionQueryString } from "./utils";

interface DetectionResp {
    data: {
        counts: Detection[];
    };
}
export const useFetchRawCounts = (
    devices: Device[],
    detectionParam: DetectionParams,
    deploymentId: string | undefined,
) => {
    const apiClient = useApiClient();
    const queryString = generateDetectionQueryString(detectionParam);
    const results = useQueries({
        queries: devices.map((device) => ({
            queryKey: ["raw-counts", device.id],
            endpoint: `/devices/${device.id}/counts${queryString}`,
            queryFn: async ({ signal }: QueryFunctionContext) => {
                const response = await apiClient.get<DetectionResp>(
                    `/devices/${device.id}/counts${queryString}`,
                    { signal },
                );
                return response.data;
            },
            enabled: !!deploymentId && !!device.id,
        })),
        combine: (results) => {
            return {
                data: results.map((result) => result.data?.counts || []),
                isPending: results.some((result) => result.isPending),
                isFetching: results.some((result) => result.isFetching),
                error: results.find((result) => result.error)?.error,
                isError: results.some((result) => result.isError),
            };
        },
    });
    return results;
};
