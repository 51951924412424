import { generateSiteKey } from "../react-query/keyFactory";
import { authService } from "../services/Services";
import { Site } from "../types/Site";
import { useApiQuery } from "./useApiQuery";

export const useFetchSite = () => {
    const queryKey = generateSiteKey();
    const token = authService.getIdentityToken();
    const { data = [], ...queryResult } = useApiQuery<Site[]>({
        queryKey: queryKey,
        endpoint: `/sites`,
        enabled: !!token,
    });
    return { data, ...queryResult };
};
