import { Box, Typography, Toolbar, Button, Tooltip } from "@mui/material";
import { useMemo } from "react";
import moment from "moment-timezone";
import Graph from "../../../components/Graphs/Graph";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CountType } from "../../../types/Count";
import { SummaryTable } from "./Table/SummaryTable";
import { formatCSVData } from "./tsutils";
import { useUserContext } from "../../../providers/useUserProvider";

const BOUNDS_SUFFIX = " Bounds";

const SummaryReport = ({ data, reportedCountType }) => {
    const enableCI = reportedCountType === CountType.Adjusted;
    const { timezone, selectedSite } = useUserContext();
    const {
        enableExport,
        hourlySeries,
        cumulativeNetSeries,
        cumulativeConfidenceIntervalSeries,
    } = useMemo(() => initialize(data), [data, enableCI]);

    const baseChartOptions = {
        animations: {
            enabled: false,
        },
        toolbar: {
            tools: {
                reset: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
            },
        },
        zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: true,
            allowMouseWheelZoom: false,
            zoomedArea: {
                fill: {
                    color: "#90CAF9",
                    opacity: 0.4,
                },
                stroke: {
                    color: "#0D47A1",
                    opacity: 0.4,
                    width: 1,
                },
            },
        },
    };

    const baseOptions = {
        legend: {
            showForZeroSeries: true,
            showForNullSeries: true,
            showForSingleSeries: true,
        },
    };

    const cumulativeChartOptions = {
        ...baseOptions,
        chart: {
            id: "cumulativeChart",
            type: enableCI ? "rangeArea" : "line",
            ...baseChartOptions,
        },
        colors: ["#48A3A1", "#8BD4DF", "#48A3A1", "#8BD4DF"],
        xaxis: {
            type: "datetime",
            title: {
                text: "Timestamp",
                style: {
                    color: "#000000",
                    fontSize: "15px",
                },
            },
            labels: {
                datetimeFormatter: {
                    year: "yyyy",
                    month: "MMM 'yy",
                    day: "dd MMM",
                    hour: "HH:mm",
                },
                datetimeUTC: false,
            },
            padding: {
                top: 0,
                right: 50,
                bottom: 0,
                left: 50,
            },
        },
        dataLabels: {
            enabled: false,
        },
        yaxis: {
            labels: {
                style: {
                    colour: "#000000",
                    fontSize: "15px",
                },
                formatter: function (value) {
                    return value?.toLocaleString();
                },
            },
            title: {
                text: `${reportedCountType} Cumulative Counts`,
                style: {
                    color: "#000000",
                    fontSize: "15px",
                },
            },
        },
        stroke: {
            curve: "straight",
            width: enableCI ? [2, 2, 0, 0] : [2, 2],
        },
        fill: {
            opacity: enableCI ? [1, 1, 0.24, 0.24] : [1, 1],
        },
        tooltip: {
            x: {
                formatter: function (val) {
                    return moment
                        .utc(val)
                        .tz(timezone)
                        .format("DD/MM/YYYY HH:mm:ss");
                },
            },
            followCursor: true,
            custom: function ({ dataPointIndex, w }) {
                const tooltipData = {};

                w.config.series.forEach((currentSeries, index) => {
                    if (w.globals.collapsedSeries.includes(index)) {
                        return;
                    }

                    const seriesName = currentSeries.name.replace(
                        BOUNDS_SUFFIX,
                        "",
                    );

                    const currentDataPoint = currentSeries.data?.[
                        dataPointIndex
                    ] || { y: null };

                    if (!tooltipData[seriesName]) {
                        tooltipData[seriesName] = {};
                    }

                    if (enableCI) {
                        if (currentSeries.type === "rangeArea") {
                            const lower = currentDataPoint.y?.[0]?.toFixed(1);
                            const upper = currentDataPoint.y?.[1]?.toFixed(1);

                            if (lower != null)
                                tooltipData[seriesName].lower = lower;
                            if (upper != null)
                                tooltipData[seriesName].upper = upper;
                        } else {
                            const adjusted = currentDataPoint.y?.toFixed(1);
                            if (adjusted != null)
                                tooltipData[seriesName].value = adjusted;
                        }
                    } else {
                        const total = currentDataPoint.y?.toFixed(1);
                        const upstream = currentDataPoint.upstream?.toFixed(1);
                        const downstream =
                            currentDataPoint.downstream?.toFixed(1);

                        if (total != null)
                            tooltipData[seriesName].value = total;
                        if (upstream != null)
                            tooltipData[seriesName].upstream = upstream;
                        if (downstream != null)
                            tooltipData[seriesName].downstream = downstream;
                    }
                });

                const formattedTooltip = Object.entries(tooltipData)
                    .map(([name, data]) => {
                        if (!data.value) {
                            return null;
                        }

                        if (enableCI) {
                            if (data.lower != null && data.upper != null) {
                                return `<div><b>${name}</b>: Lower Bound ${data.lower}, Net Adjusted ${data.value}, Upper Bound ${data.upper}</div>`;
                            } else {
                                return `<div><b>${name}</b>: Adjusted ${data.value}</div>`;
                            }
                        } else {
                            return `<div><b>${name}</b>: Net Predicted ${data.value}, Upstream ${data.upstream}, Downstream ${data.downstream}</div>`;
                        }
                    })
                    .join("");

                return `<div class="custom-tooltip">
                    ${formattedTooltip}
                </div>`;
            },
        },
    };

    const lineChartOptions = {
        chart: {
            type: "line",
            ...baseChartOptions,
        },
        colors: ["#48A3A1", "#8BD4DF"],
        stroke: {
            width: 2,
        },
        tooltip: {
            x: {
                formatter: function (val) {
                    return moment
                        .utc(val)
                        .tz(timezone)
                        .format("DD/MM/YYYY HH:mm:ss");
                },
            },
            y: {
                formatter: function (
                    value,
                    { seriesIndex, dataPointIndex, w },
                ) {
                    const dataPoint =
                        w.config.series[seriesIndex].data[dataPointIndex];

                    if (value == null) {
                        return null;
                    }

                    if (!dataPoint) {
                        return `Net: ${value}`;
                    }
                    const { upstream, downstream } = dataPoint;
                    return `Upstream: ${upstream}\nDownstream: ${downstream}\n Net: ${value}`;
                },
            },
        },

        xaxis: {
            type: "datetime",
            title: {
                text: "Timestamp",
                style: {
                    color: "#000000",
                    fontSize: "15px",
                },
            },
            labels: {
                datetimeFormatter: {
                    year: "yyyy",
                    month: "MMM 'yy",
                    day: "dd MMM",
                    hour: "HH:mm",
                },
                datetimeUTC: false,
            },
            padding: {
                top: 0,
                right: 50,
                bottom: 0,
                left: 50,
            },
        },
    };

    const hourlyGraphOptions = {
        ...lineChartOptions,
        yaxis: {
            labels: {
                style: {
                    colour: "#000000",
                    fontSize: "15px",
                },
                formatter: function (value) {
                    return value?.toLocaleString();
                },
            },
            title: {
                text: `${reportedCountType} Hourly Counts`,
                style: {
                    color: "#000000",
                    fontSize: "15px",
                },
            },
        },
    };

    function initialize(data) {
        const enableExport = Object.keys(data).every(
            (key) => data[key].counts.length !== 0,
        );

        const series = {
            cumulativeConfidenceIntervalSeries: [],
            cumulativeNetSeries: [],
            hourlySeries: [],
        };

        Object.values(data).forEach((deviceCounts) => {
            let cumulativeNetPoint = {
                name: deviceCounts.device.displayName,
                type: "line",
                zIndex: 1,
                data: deviceCounts.counts.map((item) => ({
                    x: item.timestamp,
                    y: item.imputed
                        ? null
                        : item.cumulative.Net[reportedCountType],
                    upstream: item.imputed
                        ? null
                        : item.cumulative.Upstream[reportedCountType],
                    downstream: item.imputed
                        ? null
                        : item.cumulative.Downstream[reportedCountType],
                })),
            };

            series.cumulativeConfidenceIntervalSeries.push({
                name: `${deviceCounts.device.displayName}${BOUNDS_SUFFIX}`,
                type: "rangeArea",
                zIndex: 0,
                data: deviceCounts.counts.map((item) => ({
                    x: item.timestamp,
                    y: item.imputed
                        ? [null, null]
                        : [
                              item.cumulative.Net.LowerBound,
                              item.cumulative.Net.UpperBound,
                          ],
                    adjusted: item.cumulative.Net.Adjusted,
                })),
            });

            series.cumulativeNetSeries.push(cumulativeNetPoint);

            series.hourlySeries.push({
                name: deviceCounts.device.displayName,
                type: "line",
                zIndex: 1,
                data: deviceCounts.counts.map((item) => ({
                    x: item.timestamp,
                    y: item.imputed
                        ? null
                        : item.instant.Net[reportedCountType],
                    upstream: item.imputed
                        ? "n/a"
                        : item.instant.Upstream[reportedCountType],
                    downstream: item.imputed
                        ? "n/a"
                        : item.instant.Downstream[reportedCountType],
                })),
            });
        });

        return {
            enableExport,
            ...series,
        };
    }

    const summaryCounts = Object.values(data).map((deviceCounts) => {
        const reducedCount = deviceCounts.counts
            .filter(
                (item) =>
                    item.cumulative.Net.Adjusted !== undefined &&
                    item.cumulative.Net.Adjusted !== null,
            )
            .at(-1);
        return {
            device: deviceCounts.device,
            count: reducedCount?.cumulative,
            timestamp: reducedCount?.timestamp,
        };
    });

    return (
        <>
            <Box>
                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
                    <Typography
                        sx={{ flex: "1 1 100%", color: "#043c4a" }}
                        variant="h4"
                        id="tableTitle"
                        component="div"
                    >
                        Season Summary
                    </Typography>
                    <CSVLink
                        data={formatCSVData(
                            data,
                            reportedCountType,
                            timezone,
                            "date",
                        )}
                        filename={`${selectedSite.name}_${String(reportedCountType).toLowerCase()}_season_report.csv`}
                        target="_blank"
                        enabled={`${enableExport}`}
                    >
                        <Tooltip title="Export Season Summary (CSV)">
                            <span>
                                <Button
                                    sx={{
                                        background: "#4eafb2",
                                        "&:hover": {
                                            backgroundColor: "#043c4a",
                                            boxShadow: "none",
                                        },
                                    }}
                                    startIcon={<FileDownloadIcon />}
                                    disabled={!enableExport}
                                    variant="contained"
                                >
                                    Export
                                </Button>
                            </span>
                        </Tooltip>
                    </CSVLink>
                </Toolbar>
                {summaryCounts.every(
                    (deviceCount) => deviceCount.timestamp !== undefined,
                ) ? (
                    <SummaryTable
                        data={summaryCounts}
                        showCI={enableCI}
                        label="season-summary-table"
                    />
                ) : (
                    <Typography>Nothing to display</Typography>
                )}
            </Box>

            <br />
            <Box className="FishcountsGraph">
                <Typography
                    variant="h4"
                    sx={{ color: "#043C4A" }}
                    component="div"
                >
                    Cumulative Counts
                </Typography>

                <Graph
                    options={cumulativeChartOptions}
                    series={[
                        ...cumulativeNetSeries,
                        ...(enableCI ? cumulativeConfidenceIntervalSeries : []),
                    ]}
                ></Graph>
            </Box>
            <br />
            <Box className="FishcountsGraph">
                <Typography
                    variant="h4"
                    sx={{ color: "#043C4A" }}
                    component="div"
                >
                    Hourly Counts
                </Typography>
                <Graph
                    options={hourlyGraphOptions}
                    series={hourlySeries}
                ></Graph>
            </Box>
        </>
    );
};

export default SummaryReport;
