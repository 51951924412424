import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { Site } from "../types/Site";
import { authService } from "../services/Services";
import { useFetchSite } from "../hooks/useFetchSites";
import { queryClient } from "../react-query/queryClient";

export interface IUserDetails {
    username: string | null;
}

export interface UserContextType {
    userDetails: IUserDetails | null;
    setUserDetails: (userDetails: IUserDetails | null) => void;
    allowedSites: Site[];
    selectedSite: Site | null;
    setSelectedSite: (site: Site | null) => void;
    timezone: string;
}

interface Props {
    setErrorState(error: any): void;
}

const UserContext = createContext<UserContextType | null>(null);

const UserProvider: React.FC<PropsWithChildren<Props>> = ({
    children,
    setErrorState,
}) => {
    const { data: sites, error, isError } = useFetchSite();
    const [userDetails, setUserDetails] = useState<IUserDetails | null>(
        authService.getIdentityToken()
            ? { username: authService.getUsername() }
            : null,
    );
    const [allowedSites, setAllowedSites] = useState<Site[]>([]);
    const [selectedSite, setSelectedSite] = useState<Site | null>(null);

    useEffect(() => {
        if (userDetails === null) {
            setAllowedSites([]);
            setSelectedSite(null);
        }
        if (!userDetails) {
            queryClient.setQueryData(["sites"], []);
            queryClient.invalidateQueries();
            setAllowedSites([]);
            setSelectedSite(null);
        }

        if (isError) {
            setErrorState({
                hasError: true,
                message: "unable to load Sites",
            });
            setAllowedSites([]);
            setSelectedSite(null);
        } else if (sites) {
            setAllowedSites(sites);
            const savedSiteName = localStorage.getItem("selected_site");
            let siteToSelect: Site | null = null;

            if (savedSiteName) {
                // Try to find the saved site
                siteToSelect =
                    sites.find((site) => site.displayName === savedSiteName) ||
                    null;
            }

            // If no saved site or saved site not found, use the first site
            if (!siteToSelect && sites.length > 0) {
                siteToSelect = sites[0];
                localStorage.setItem("selected_site", siteToSelect.displayName);
            }

            setSelectedSite(siteToSelect);
        }
    }, [userDetails, setErrorState, sites, error]);

    useEffect(() => {
        if (selectedSite) {
            localStorage.setItem("selected_site", selectedSite.displayName);
        }
    }, [selectedSite]);

    useEffect(() => {
        if (!(userDetails && userDetails != null)) {
            setAllowedSites([]);
            setSelectedSite(null);
        }
    }, [userDetails, setErrorState]);

    return (
        <UserContext.Provider
            value={{
                userDetails,
                setUserDetails,
                allowedSites,
                selectedSite,
                setSelectedSite,
                timezone: "America/Halifax",
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider, UserContext };
