import { useCallback, useMemo, useContext } from "react";
import moment from "moment-timezone";
import { ExpandableTable } from "../../../components/Table/ExpandableTable";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { EMPTY_DATA_STRING } from "./DailyReport";
import { UserContext } from "../../../providers/UserProvider";
import { scalingBiasCorrection } from "../../../types/Count";
import { PipelineMetricMap } from "../../../types/Pipeline";
import DownloadButton from "../../../components/Buttons/DownloadButton";
import { useQueryClient } from "@tanstack/react-query";
import { useUserContext } from "../../../providers/useUserProvider";
import { useApiClient } from "../../../providers/ApiClientProvider";
import { generateDetectionQueryString } from "../../../hooks/utils";

const getDeviceOrdinal = (displayName) => {
    return parseInt(displayName.replace(/[^\d]/g, ""), 0);
};

const MinuteReport = ({
    selectedDate,
    site,
    deviceCounts,
    devices,
    setErrorState,
}) => {
    const { timezone } = useUserContext(UserContext);

    const apiClient = useApiClient();

    const queryClient = useQueryClient();

    const data = useMemo(
        () => processDeviceCounts(deviceCounts),
        [deviceCounts, selectedDate, site],
    );

    function processDeviceCounts(dcs) {
        let data = new Map();
        let type = "hour";

        const timeFormat = "HH:mm";

        Object.values(dcs).forEach((deviceCount) => {
            deviceCount.counts.forEach((count) => {
                const startTime = moment(count.timestamp)
                    .tz(timezone)
                    .startOf("hour")
                    .format(timeFormat);
                const endTime = moment(count.timestamp)
                    .tz(timezone)
                    .endOf("hour")
                    .format(timeFormat);
                const timerange = `${startTime} - ${endTime}`;
                const timeRangeData = data.get(timerange) || {};
                const upstream = count.instant
                    ? count.instant.Upstream.Predicted || 0
                    : EMPTY_DATA_STRING;
                const downstream = count.instant
                    ? count.instant.Downstream.Predicted || 0
                    : EMPTY_DATA_STRING;
                data.set(timerange, {
                    ...timeRangeData,
                    [deviceCount.device.name]: {
                        upstream: upstream,
                        downstream: downstream,
                        type: type,
                    },
                });
            });
        });

        const final = Array.from(data, ([key, value]) => ({
            id: key,
            timerange: key,
            ...value,
        }));
        return final;
    }

    const processApiData = useCallback(
        (deviceCountData, timeFormat = "HH:mm", isSubRow = false) => {
            let combinedData = new Map();

            deviceCountData.forEach((deviceData) => {
                if (
                    !deviceData ||
                    !deviceData.countData ||
                    deviceData.countData.length === 0
                )
                    return;

                deviceData.countData.forEach((count) => {
                    if (!count.timestamp) return;

                    const startTime = moment(count.timestamp)
                        .tz(timezone)
                        .startOf("minute")
                        .format(timeFormat);

                    const timeRangeData = combinedData.get(startTime) || {};
                    combinedData.set(startTime, {
                        ...timeRangeData,
                        [deviceData.device.name]: {
                            upstream: count.instant.Upstream.Predicted,
                            downstream: count.instant.Downstream.Predicted,
                            timestamp: count.timestamp,
                            type: "minute",
                            deviceId: deviceData.device.id,
                        },
                    });
                });
            });

            let finalData = Array.from(combinedData, ([key, value]) => ({
                id: key,
                timerange: key,
                ...value,
            }));
            if (isSubRow) {
                finalData.sort((a, b) =>
                    moment(a.timerange, "mm").diff(moment(b.timerange, "mm")),
                );
            }
            return finalData;
        },
        [site],
    );

    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: moment(selectedDate).format("MMMM D, YYYY"),
                columns: [
                    {
                        accessorKey: "timerange",
                        header: "Time Range",
                        size: 25,
                        muiTableBodyCellProps: {
                            sx: {
                                textAlign: "center",
                            },
                        },
                        muiTableHeadCellProps: {
                            align: "center",
                        },
                    },
                ],
            },
        ];

        if (devices) {
            const sortedDevices = [...devices].sort((a, b) => {
                const ordinalA = getDeviceOrdinal(a.displayName);
                const ordinalB = getDeviceOrdinal(b.displayName);
                return ordinalA - ordinalB;
            });
            const deviceColumns = sortedDevices.map((device) => ({
                header: device.displayName,
                muiTableHeadCellProps: {
                    align: "center",
                },
                columns: [
                    {
                        header: "Upstream",
                        size: 25,
                        accessorFn: (row) => {
                            const show =
                                row[device.name]?.upstream !== undefined &&
                                row[device.name]?.upstream !== null;
                            if (show) {
                                return row[device.name].upstream;
                            }
                            return EMPTY_DATA_STRING;
                        },
                        id: `${device.name}-upstream`,
                        muiTableBodyCellProps: {
                            sx: {
                                textAlign: "center",
                            },
                        },
                        muiTableHeadCellProps: {
                            align: "center",
                        },
                    },
                    {
                        header: "Downstream",
                        size: 25,
                        accessorFn: (row) =>
                            row[device.name]?.downstream !== undefined &&
                            row[device.name]?.downstream !== null
                                ? row[device.name]?.downstream
                                : EMPTY_DATA_STRING,
                        id: `${device.name}-downstream`,
                        muiTableBodyCellProps: {
                            sx: {
                                textAlign: "center",
                            },
                        },
                        muiTableHeadCellProps: {
                            align: "center",
                        },
                    },
                    {
                        header: "",
                        size: 25,
                        accessorKey: `${device.name}-vid`,
                        Cell: ({ row }) => {
                            const deviceData = row.original[device.name];
                            if (!deviceData) {
                                return null;
                            }
                            if (
                                !deviceData?.timestamp ||
                                deviceData.type !== "minute"
                            )
                                return null;

                            const filename = `${site.name}-${device.name}-${moment(deviceData.timestamp).format("YYYYMMDDTHHmm")}.mp4`;

                            return (
                                <DownloadButton
                                    filename={filename}
                                    deviceId={device.id}
                                    timestamp={deviceData.timestamp}
                                    size="small"
                                    setErrorState={setErrorState}
                                    buttonComponent={({
                                        onClick,
                                        isDownloading,
                                        disabled,
                                    }) => (
                                        <Tooltip title={`Download ${filename}`}>
                                            <span>
                                                <IconButton
                                                    aria-label={`Download ${filename}`}
                                                    size="small"
                                                    sx={{
                                                        background: "#4eafb2",
                                                        "&:hover": {
                                                            backgroundColor:
                                                                "#043c4a",
                                                            boxShadow: "none",
                                                        },
                                                    }}
                                                    color="light"
                                                    disabled={disabled}
                                                    onClick={onClick}
                                                >
                                                    {isDownloading ? (
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    ) : (
                                                        <DownloadIcon />
                                                    )}
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    )}
                                />
                            );
                        },
                        muiTableBodyCellProps: {
                            sx: {
                                textAlign: "center",
                            },
                        },
                        muiTableHeadCellProps: {
                            align: "center",
                        },
                    },
                ],
            }));

            return [...baseColumns, ...deviceColumns];
        }
    }, [devices, data, selectedDate]);

    const fetchSubRows = useCallback(
        async (parentRow) => {
            try {
                const [startTime, endTime] = parentRow.timerange.split(" - ");
                const startDate = moment
                    .tz(selectedDate, timezone)
                    .format("YYYY-MM-DD");
                const startTimestamp = moment
                    .tz(
                        `${startDate} ${startTime}`,
                        "YYYY-MM-DD HH:mm",
                        timezone,
                    )
                    .toISOString();
                const endTimestamp = moment
                    .tz(
                        `${startDate} ${startTime}`,
                        "YYYY-MM-DD HH:mm",
                        timezone,
                    )
                    .startOf("hour")
                    .add(1, "hour")
                    .toISOString();

                const detectionParams = {
                    startTimestamp,
                    endTimestamp,
                    interval: "1m",
                };

                const subRowsData = await Promise.all(
                    devices.map(async (device) => {
                        const minuteCounts = await queryClient.fetchQuery({
                            queryKey: [
                                "minute-detections",
                                device.id,
                                startTimestamp,
                                endTimestamp,
                            ],
                            queryFn: async ({ signal }) => {
                                const response = await apiClient.get(
                                    `/devices/${device.id}/counts${generateDetectionQueryString(
                                        detectionParams,
                                    )}`,
                                    { signal },
                                );
                                return response.data.counts;
                            },
                        });

                        if (MinuteReport.Error) {
                            setErrorState({
                                hasError: true,
                                message: MinuteReport.Error.message,
                            });
                        }

                        const pipelines = [
                            ...new Set(
                                minuteCounts
                                    ? minuteCounts.map(
                                          (count) => count.pipelineID,
                                      )
                                    : [],
                            ),
                        ];

                        const pipelineMetricsPromise = pipelines.map(
                            (pipeline) =>
                                queryClient.fetchQuery({
                                    queryKey: ["pipeline-metrics", pipeline],
                                    queryFn: ({ signal }) =>
                                        apiClient.get(
                                            `/pipelines/${pipeline}`,
                                            { signal },
                                        ),
                                }),
                        );

                        const pipelineMetricsResult = await Promise.all(
                            pipelineMetricsPromise,
                        );

                        const pipelineMetrics = {};

                        pipelines.forEach((pipeline, index) => {
                            pipelineMetrics[pipeline] =
                                pipelineMetricsResult[index]?.metrics || [];
                        });

                        return {
                            device,
                            countData: minuteCounts
                                ? scalingBiasCorrection(
                                      minuteCounts,
                                      new PipelineMetricMap(pipelineMetrics),
                                  )
                                : [],
                        };
                    }),
                );
                const processedSubRows = processApiData(
                    subRowsData,
                    "mm",
                    true,
                    "minute",
                );

                return processedSubRows.map((subRow) => ({
                    ...subRow,
                    parentId: parentRow.id,
                }));
            } catch (error) {
                console.log(error);
                setErrorState({
                    hasError: true,
                    message: `unable to load minute-level data`,
                });
                console.log(error);
                return [];
            }
        },
        [devices, selectedDate, data, setErrorState],
    );

    return (
        <ExpandableTable
            columns={columns}
            data={data}
            fetchSubRows={fetchSubRows}
        />
    );
};

export default MinuteReport;
