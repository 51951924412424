import { useMemo } from "react";
import { Deployment } from "../types/Deployment";
import { Device } from "../types/Device";
import { PipelineMetricMap } from "../types/Pipeline";
import { DeviceCounts } from "../pages/ReportLayout/Components/Report";
import {
    alignCounts,
    cumulativeSum,
    isCIEligible,
    scalingBiasCorrection,
} from "../types/Count";
import moment from "moment-timezone";

export const useProcessedCounts = (
    devices: Device[] | null,
    rawCountArray: any[] | null,
    pipelineMetricMap: PipelineMetricMap | null,
    deployment: Deployment | null,
    timezone: string,
) => {
    const reuslts = useMemo(() => {
        if (
            !rawCountArray ||
            !devices ||
            !pipelineMetricMap ||
            !deployment ||
            !timezone
        ) {
            return { deviceCounts: undefined, ciEnabled: undefined };
        }

        let deviceCounts: DeviceCounts = {};

        let shoulEnableCIs = false;

        devices.forEach((device, index) => {
            const rawCounts = rawCountArray[index];
            if (rawCounts) {
                shoulEnableCIs =
                    shoulEnableCIs ||
                    isCIEligible(rawCounts, pipelineMetricMap);
                deviceCounts[device.name] = {
                    device,
                    counts: scalingBiasCorrection(rawCounts, pipelineMetricMap),
                };
            }
        });

        const aligned = alignCounts(
            devices
                .map((device) => {
                    return deviceCounts[device.name]?.counts;
                })
                .filter((item) => item !== undefined),
            moment(deployment?.startTime).tz(timezone).toDate(),
            moment(deployment?.endTime).tz(timezone).toDate(),
        );

        devices.forEach((device, i) => {
            if (deviceCounts[device.name]) {
                deviceCounts[device.name].counts = aligned[i];
                deviceCounts[device.name].counts = cumulativeSum(
                    deviceCounts[device.name].counts,
                );
            }
        });

        // pre-sort devices by name.
        deviceCounts = Object.keys(deviceCounts)
            .sort()
            .reduce((obj: DeviceCounts, key) => {
                obj[key] = deviceCounts[key];
                return obj;
            }, {});

        return { counts: deviceCounts, ciEnabled: shoulEnableCIs };
    }, [rawCountArray, devices, pipelineMetricMap, deployment, timezone]);

    return reuslts;
};
