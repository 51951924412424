export function compareDateHour(x, y) {
    if (x.date.value < y.date.value) {
        return -1;
    } else if (x.date.value > y.date.value) {
        return 1;
    } else {
        if (x.hour.value < y.hour.value) {
            return -1;
        } else if (x.hour.value > y.hour.value) {
            return 1;
        }
    }

    return 0;
}

export function arrayBufferToBase64(buffer) {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}
