import { Typography, Button, Toolbar, Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import moment from "moment-timezone";
import { apiClient } from "../../../services/Services";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useState, useEffect, useMemo } from "react";
import { arrayBufferToBase64 } from "./utils";
import { formatCSVData } from "./tsutils";
import ImageGrid from "../../../components/Grid/ImageGrid";
import MinuteReport from "./MinuteReport";

import { useUserContext } from "../../../providers/useUserProvider";
import { SummaryTable } from "./Table/SummaryTable";
import { CountType, cumulativeSum } from "../../../types/Count";
import { queryClient } from "../../../react-query/queryClient";
import { generateVideoKey } from "../../../react-query/keyFactory";

export const EMPTY_DATA_STRING = "-";

const DailyReport = ({ setErrorState, devices, deviceCounts, deployment }) => {
    const { selectedSite: site, timezone } = useUserContext();
    const [selectedDate, setSelectedDate] = useState(() => {
        let storedDate = localStorage.getItem(`${site.id}_date_selection`);
        return storedDate ? moment(storedDate) : moment().tz(timezone);
    });

    const [images, setImages] = useState([]);
    const [isImageLoading, setIsImageLoading] = useState(true);

    const data = useMemo(
        () => filterData(deviceCounts),
        [selectedDate, deviceCounts],
    );

    const csvDisabled = useMemo(
        () =>
            data === undefined ||
            ![...Object.values(data)].some(
                (deviceCounts) => deviceCounts.counts.length !== 0,
            ),
        [data],
    );

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
        localStorage.setItem(`${site.id}_date_selection`, newDate);
    };

    const getDeviceImage = async (deviceId, time) => {
        try {
            const response = await queryClient.fetchQuery({
                queryKey: generateVideoKey(deviceId, time),
                queryFn: async ({ signal }) => {
                    try {
                        return await apiClient.get(
                            `/devices/${deviceId}/videos?time=${time}`,
                            { signal },
                        );
                    } catch (error) {
                        console.error("Error fetching video data:", error);
                        return { data: [] };
                    }
                },
            });

            const videos = response?.data || [];
            if (videos && videos.length > 0) {
                const videoId = videos[0].id;
                try {
                    const buffer = await queryClient.fetchQuery({
                        queryKey: generateVideoKey(videoId),
                        queryFn: async ({ signal }) => {
                            try {
                                const response = await apiClient.get(
                                    `/videos/${videoId}/frames?offset=0`,
                                    {
                                        signal,
                                        responseType: "arraybuffer",
                                        headers: { Accept: "image/jpeg" },
                                    },
                                );
                                return response;
                            } catch (error) {
                                console.error(
                                    "Error fetching frame data:",
                                    error,
                                );
                                return new ArrayBuffer(8);
                            }
                        },
                    });

                    const binaryImage = arrayBufferToBase64(buffer);
                    return binaryImage;
                } catch (error) {
                    console.error("Error in fetchQuery for frames:", error);
                    return null;
                }
            }
            return null;
        } catch (error) {
            console.error("Error in getDeviceImage:", error);
            return null;
        }
    };
    const loadImages = async (startDate) => {
        setIsImageLoading(true);
        let allImages = [];
        await Promise.all(
            Object.values(deviceCounts).map(async (deviceCount) => {
                var utcStartTimestamp = moment
                    .utc(startDate)
                    .format("YYYY-MM-DDTHH:mm:ss[Z]");
                const imageBase64 = await getDeviceImage(
                    deviceCount.device.id,
                    utcStartTimestamp,
                );
                allImages.push({
                    image: imageBase64,
                    deviceName: deviceCount.device.displayName,
                    ordinal: parseInt(
                        deviceCount.device.displayName.replace(/[^\d]/g, ""),
                    ),
                });
                return allImages;
            }),
        );
        setIsImageLoading(false);
        setImages(allImages.sort((a, b) => a.ordinal - b.ordinal));
    };

    function filterData(data) {
        const dailyData = {};

        Object.entries(data).forEach(([key, deviceCount]) => {
            dailyData[key] = {
                device: deviceCount.device,
                counts: deviceCount.counts.filter((count) => {
                    const start = moment(selectedDate).startOf("day").toDate();
                    const end = moment(selectedDate).endOf("day").toDate();
                    return (
                        start.getTime() <= count.timestamp.getTime() &&
                        end.getTime() >= count.timestamp.getTime()
                    );
                }),
            };
            const cumulatives = cumulativeSum(dailyData[key].counts);
            dailyData[key].counts.forEach((count, index) => {
                dailyData[key].counts[index].cumulative =
                    cumulatives[index].cumulative;
            });
        });

        return dailyData;
    }

    useEffect(() => {
        const startDate = moment(selectedDate)
            .clone()
            .tz(timezone)
            .startOf("day");

        loadImages(startDate);
    }, [selectedDate, data]);

    const summaryCounts = Object.values(data).map((deviceCounts) => {
        const reducedCount = deviceCounts.counts
            .filter(
                (item) =>
                    item.cumulative.Net.Predicted !== undefined &&
                    item.cumulative.Net.Predicted !== null,
            )
            .at(-1);
        return {
            device: deviceCounts.device,
            count: reducedCount?.cumulative,
            timestamp: reducedCount?.timestamp,
        };
    });

    return (
        <>
            <DatePicker
                value={
                    selectedDate >= moment.tz(deployment.endTime, timezone) ||
                    selectedDate < moment.tz(deployment.startTime, timezone)
                        ? setSelectedDate(
                              moment.tz(deployment.startTime, timezone),
                          )
                        : selectedDate
                }
                onChange={handleDateChange}
                timezone={timezone}
                minDate={deployment.startTime}
                maxDate={deployment.endTime}
            />
            <br />

            <Typography
                variant="h4"
                component="div"
                paddingLeft="16px"
                minHeight="48px"
                sx={{ color: "#043C4A", padding: 1 }}
            >
                Daily Summary
            </Typography>
            <ImageGrid
                data={
                    images.length !== 0
                        ? images
                        : Object.values(data).map(
                                (deviceCount) => deviceCount.device,
                            )
                          ? Array.from(
                                { length: Object.keys(data).length },
                                (_, i) => ({
                                    ordinal: i,
                                    image: "",
                                }),
                            )
                          : [{ ordinal: 1, image: "" }]
                }
                isLoading={isImageLoading}
            ></ImageGrid>
            {summaryCounts.every(
                (deviceCount) => deviceCount.timestamp !== undefined,
            ) ? (
                <SummaryTable
                    data={summaryCounts}
                    showCI={false}
                    label="hourly-summary-table"
                />
            ) : (
                <Typography>Nothing to display</Typography>
            )}
            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
                <Typography
                    sx={{ flex: "1 1 100%", color: "#043c4a" }}
                    variant="h4"
                    id="tableTitle"
                    component="div"
                >
                    Hourly Report
                </Typography>
                <CSVLink
                    data={formatCSVData(
                        filterData(data, selectedDate),
                        CountType.Predicted,
                        timezone,
                        "hour",
                    )}
                    filename={`${site.name}_hourly_report_${
                        selectedDate.format().split("T")[0]
                    }.csv`}
                    onClick={() => {
                        return !csvDisabled;
                    }}
                    target="_blank"
                >
                    <Tooltip title="Export Daily Hourly Summary (CSV)">
                        <span>
                            <Button
                                sx={{
                                    background: "#4eafb2",
                                    "&:hover": {
                                        backgroundColor: "#043c4a",
                                        boxShadow: "none",
                                    },
                                }}
                                startIcon={<FileDownloadIcon />}
                                disabled={csvDisabled}
                                variant="contained"
                            >
                                Export
                            </Button>
                        </span>
                    </Tooltip>
                </CSVLink>
            </Toolbar>

            <MinuteReport
                site={site}
                devices={devices}
                deviceCounts={data}
                selectedDate={selectedDate}
                setErrorState={setErrorState}
            />
        </>
    );
};

export default DailyReport;
