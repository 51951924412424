import { Deployment } from "../types/Deployment";
import { Site } from "../types/Site";
import { useApiQuery } from "./useApiQuery";
import { Device } from "../types/Device";

export const useFetchDevice = (
    site: Site | null,
    deployment: Deployment | null,
) => {
    const queryKey = ["device", site?.id, deployment?.id];

    const { data = [], ...queryResult } = useApiQuery<Device[]>({
        queryKey: queryKey,
        endpoint: `/sites/${site?.id}/devices?deploymentId=${deployment?.id}`,
        enabled: !!deployment && !!site,
    });
    return { data, ...queryResult };
};
