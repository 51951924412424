import { QueryKey, useQuery } from "@tanstack/react-query";
import { useApiClient } from "../providers/ApiClientProvider";

interface UseApiQueryOptions<T> {
    queryKey: QueryKey;
    endpoint: string;
    enabled?: boolean;
    select?: (data: T) => any;
}

export function useApiQuery<T>({
    queryKey,
    endpoint,
    enabled = true,
    select,
}: UseApiQueryOptions<T>) {
    const apiClient = useApiClient();

    return useQuery<T, Error>({
        queryKey,
        queryFn: async ({ signal }) => {
            const response = await apiClient.get<{ data: T }>(endpoint, {
                signal,
            });
            return response.data;
        },
        enabled,
        select,
    });
}
