import { QueryClient, QueryClientConfig } from "@tanstack/react-query";

export const queryClientOptions: QueryClientConfig = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            gcTime: 900000, //15 Minutes
            staleTime: 600000, //10 minutes
        },
    },
};

export const queryClient = new QueryClient(queryClientOptions);
