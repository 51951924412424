import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { apiClient } from "../services/Services";

interface ErrorState {
    hasError: boolean;
    message: string;
}

interface DownloadFileParams {
    deviceId: string;
    timestamp: string;
    filename: string;
}

interface UseDownloadFileOptions
    extends Omit<
        UseMutationOptions<void, Error, DownloadFileParams, unknown>,
        "mutationFn"
    > {
    setErrorState: (state: ErrorState) => void;
}

export default function useDownloadFile(options: UseDownloadFileOptions) {
    const mutation = useMutation({
        mutationFn: async ({ deviceId, timestamp }: DownloadFileParams) => {
            const response = await apiClient.get(
                `devices/${deviceId}/videos?time=${timestamp}`,
                {
                    responseType: "blob",
                    headers: {
                        accept: "video/mp4",
                    },
                },
            );
            return response;
        },
        onSuccess: (response, variables) => {
            if (!(response instanceof Blob)) {
                throw new Error("Faild to download: Invalid response format");
            }
            const downloadUrl = window.URL.createObjectURL(response);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = variables.filename;
            document.body.appendChild(link);
            link.click();

            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
        },
        onError: (error) => {
            console.error("Download failed", error);
            options.setErrorState({
                hasError: true,
                message: error.message,
            });
        },
    });

    return mutation;
}
