import { DynamicTable } from "../../../../components/Table/DynamicTable";
import { CountType } from "../../../../types/Count";
import { Direction } from "../../../../types/Direction";
import { ColumnDef, RowData } from "@tanstack/react-table";
import { useMemo } from "react";
import { Device } from "../../../../types/Device";
import moment from "moment";

/** A map of counts, keyed first by
 * the CountType, followed by device name,
 * followed by direction, which yields the count.
 */
interface DeviceCounts {
    device: Device;
    count: {
        [key in Direction]: {
            [CountType.LowerBound]?: number | undefined | null;
            [CountType.Adjusted]: number | undefined | null;
            [CountType.UpperBound]?: number | undefined | null;
            [CountType.Predicted]?: number | undefined | null;
        };
    };
    timestamp: Date;
}

// Extend the ColumnMeta interface with a function to apply conditional styling.
declare module "@tanstack/react-table" {
    interface ColumnMeta<TData extends RowData, TValue> {
        getStyle?: (cellValue: any, validatedOn: Date) => React.CSSProperties;
    }
}

const initializeColumns = (
    data: DeviceCounts[],
    enableCI: boolean,
): ColumnDef<Row, any>[] => {
    const columns: ColumnDef<Row, any>[] = [];

    if (enableCI) {
        columns.push({
            accessorKey: "type",
            cell: ({ cell }) => {
                let typeStr = cell
                    .getValue()
                    .replace(/([a-z])([A-Z])/g, "$1 $2");
                typeStr = typeStr.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
                return typeStr;
            },
            header: () => <span></span>,
        });
    }

    const order: Record<Direction, number> = {
        [Direction.Upstream]: 0,
        [Direction.Downstream]: 1,
        [Direction.Net]: 2,
    };

    data.forEach((deviceCount) => {
        let directions: string[] = [];
        if (deviceCount.count) {
            directions = Object.keys(deviceCount.count).sort((a, b): number => {
                return (
                    order[a as keyof typeof Direction] -
                    order[b as keyof typeof Direction]
                );
            });
        }

        const deviceCols: ColumnDef<Row, any>[] = [];
        directions.forEach((direction: string) => {
            deviceCols.push({
                accessorFn: (row) => {
                    return row[`${deviceCount.device.name}-${direction}`];
                },
                id: `${deviceCount.device.name}-${direction}`,
                cell: (info) => {
                    const value = info.getValue();
                    const style = info.column.columnDef.meta?.getStyle?.(
                        value,
                        deviceCount.device.accurateAsOf,
                    );
                    return <span style={style} aria-label={`${deviceCount.device.name}-${direction}`} >{value.value}</span>;
                },
                header: () => <span>{`${direction}`}</span>,
                meta: {
                    getStyle: (
                        cellValue: { created: Date },
                        validatedOn: Date,
                    ) =>
                        moment(cellValue.created).isSameOrAfter(validatedOn)
                            ? { color: "inherit" }
                            : { color: "#CCCCCC" },
                },
            });
        });

        columns.push({
            header: deviceCount.device.displayName,
            columns: deviceCols,
        });
    });

    return columns;
};

interface Row {
    type: string;
    [key: string]:
        | string
        | {
              value: number;
              created: Date;
          };
}

const formatData = (data: DeviceCounts[], enableCI: boolean): Row[] => {
    const typedRows: Record<CountType, Row> = {
        [CountType.LowerBound]: { type: CountType.LowerBound },
        [CountType.Adjusted]: { type: CountType.Adjusted },
        [CountType.UpperBound]: { type: CountType.UpperBound },
        [CountType.Predicted]: { type: CountType.Predicted },
    };

    data.forEach((deviceCount) => {
        Object.keys(deviceCount.count).forEach((key) => {
            if (!Object.values<string>(Direction).includes(key)) {
                return;
            }

            Object.keys(
                deviceCount.count[key as keyof typeof Direction],
            ).forEach((type) => {
                typedRows[type as CountType][
                    `${deviceCount.device.name}-${key}`
                ] = {
                    value: deviceCount.count[key as keyof typeof Direction][
                        type as keyof typeof CountType
                    ]!,
                    created: deviceCount.timestamp,
                };
            });
        });
    });

    return Object.values(typedRows);
};

export interface Properties {
    data: DeviceCounts[];
    showCI: boolean;
    label: string
}

/** Component that displays a high-level summary of the season's
 * Directional Counts.
 *
 * If props.showCI is true, then all confidence bounds will be displayed
 * in their own row. If false, then only the Predicted counts
 * will be displayed.
 *
 * Data state is expected to be controlled by the parent.
 */
export const SummaryTable = (props: Properties) => {
    const columns = useMemo<ColumnDef<Row, any>[]>(
        () => initializeColumns(props.data, props.showCI),
        [props.data, props.showCI],
    );

    const display: string[] = [];
    if (props.showCI) {
        display.push(CountType.Adjusted as string);
        display.push(CountType.LowerBound as string);
        display.push(CountType.UpperBound as string);
    }

    const filteredData = formatData(props.data, props.showCI)?.filter((d) =>
        (props.showCI && display.includes(d.type)) || (!props.showCI && (d.type === CountType.Predicted as string))
    );

    if (filteredData.length === 0) {
        return;
    }

    return <DynamicTable columns={columns} data={filteredData} label={props.label} />;
};
