import { createContext, useContext } from "react";
import { AppConfiguration } from "../types/Config";

export const AppConfigContext = createContext<AppConfiguration | null>(null);

export const useAppConfig = () => {
    const context = useContext(AppConfigContext);

    if (!context) {
        throw new Error("useAppcConfig must be used within AppConfigProvider");
    }
    return context;
};
