import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
    initializeApiClient,
    initializeAuthService,
    initializeV1TaglessService,
} from "./services/Services";

fetch("/config.json")
    .then((r) => r.json())
    .then((config) => {
        initializeV1TaglessService({
            baseUrl: config.apiHost,
            version: "v1",
        });
        initializeApiClient({
            baseUrl: config.apiHost,
            version: "v1",
        });
        initializeAuthService(config.cognitoUserPoolId, config.cognitoClientId);
        return config;
    })
    .then((config) => {
        const root = ReactDOM.createRoot(document.getElementById("root"));
        root.render(
            <React.StrictMode>
                <BrowserRouter>
                    <App config={config} />
                </BrowserRouter>
            </React.StrictMode>,
        );
    });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
