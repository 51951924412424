import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";

// Generic type for selectable objects
type WithId = {
    id: string;
    [key: string]: any;
};

interface Props<T extends WithId> {
    options: T[];
    selectedItem: T | null;
    onSelect: (item: T | null) => void;
    isLoading: boolean;
    getOptionLabel: (option: T) => string;
    label?: string;
}

function Selector<T>({
    options,
    selectedItem,
    onSelect,
    isLoading,
    getOptionLabel,
    label = "Select Object",
}: Props<T & WithId>): React.ReactElement {
    const handleChange = (event: SelectChangeEvent) => {
        const selectedId = event.target.value;
        if (selectedId === "") {
            onSelect(null);
        } else {
            const selected =
                options.find((item) => item.id === selectedId) || null;
            onSelect(selected);
        }
    };
    return (
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="object-selector-label">{label}</InputLabel>
            <Select
                labelId="object-selector-label"
                id="object-selector"
                value={selectedItem?.id || ""}
                label={label}
                onChange={handleChange}
                size="small"
                disabled={isLoading}
            >
                {options?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {getOptionLabel(item)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default Selector;
