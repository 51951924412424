import { queryKeys } from "./constants";

export const generateDeploymentKey = (siteId: string) => {
    return [queryKeys.deployments, siteId];
};

export const generateVideoKey = (deviceId: string, time: string) => {
    return [queryKeys.videos, deviceId, time];
};

export const generateDevicesKey = (siteId: string, deploymentId: string) => {
    return [queryKeys.devices, siteId, deploymentId];
};

export const generateImageKey = (videoId: string) => {
    return [queryKeys.devices, videoId];
};

export const generateSiteKey = () => {
    return [queryKeys.sites];
};
