import CognitoDriver from "./Auth/CognitoDriver";
import AuthClient from "./Auth/AuthClient";
import AuthService from "./Auth/AuthService";
import ApiClient, { ApiConfiguration } from "./TaglessApi/AxiosClient";
import TaglessService, {
    TaglessApiClient,
} from "./TaglessApi/TaglessApiService";

// Tagless API Service
export let v1TaglessService: TaglessService;

export const initializeV1TaglessService = (config: ApiConfiguration) => {
    const taglessApiClient = new TaglessApiClient(new ApiClient(config));
    v1TaglessService = new TaglessService(taglessApiClient);
};

export let apiClient: ApiClient;

export const initializeApiClient = (config: ApiConfiguration) => {
    apiClient = new ApiClient(config);
};
// Authorization Service
export let authService: AuthService;

export const initializeAuthService = (userPoolId: string, clientId: string) => {
    const cognitoClient = new AuthClient(
        new CognitoDriver(),
        userPoolId,
        clientId,
    );
    authService = new AuthService(cognitoClient);
};
