import { Direction } from "../../../types/Direction";
import _ from "lodash";
import {
    Count,
    CountType,
    cumulativeSum,
    formatIntervalBound,
    CountValue,
} from "../../../types/Count";
import moment, { unitOfTime } from "moment-timezone";
import { DeviceCounts } from "./Report";

function formatDate(
    date: Date,
    timezone: string,
    unit: unitOfTime.StartOf,
): string {
    const dateTemplate = "YYYY-MM-DD";
    const timeTemplate = [];
    const displayDate: Date = new Date(date);

    if (unit === "minute" || unit === "hour") {
        timeTemplate.push("HH:mm");

        if (unit === "hour") {
            displayDate.setMinutes(0);
        }
    }

    return moment(displayDate)
        .tz(timezone)
        .format([dateTemplate, timeTemplate].join(" "))
        .trimEnd();
}

enum Period {
    Instant = "instant",
    Cummulative = "cumulative",
}

interface CountHeader {
    direction: Direction;
    period: Period;
    type: CountType;
}

function formatHeader(countHeader: CountHeader): string {
    return `${countHeader.direction} ${countHeader.period === Period.Cummulative ? countHeader.period.charAt(0).toUpperCase() + countHeader.period.substring(1) + " " : ""}${formatIntervalBound(countHeader.type)}`;
}

export const formatCSVData = (
    data: DeviceCounts,
    reportedCountType: CountType.Adjusted | CountType.Predicted,
    timezone: string,
    unit: unitOfTime.StartOf,
): string => {
    const headers: string[] = [
        String(unit).charAt(0).toUpperCase() + String(unit).slice(1),
    ];

    const baseHeaders: Omit<CountHeader, "type">[] = [
        {
            direction: Direction.Upstream,
            period: Period.Instant,
        },
        {
            direction: Direction.Downstream,
            period: Period.Instant,
        },
        {
            direction: Direction.Net,
            period: Period.Instant,
        },
        {
            direction: Direction.Net,
            period: Period.Cummulative,
        },
    ];

    const headerSuffixes: CountType[] =
        reportedCountType === CountType.Adjusted
            ? [CountType.LowerBound, reportedCountType, CountType.UpperBound]
            : [reportedCountType];

    const deviceHeaders: CountHeader[] = baseHeaders.flatMap((base) => {
        return headerSuffixes.map((suffix) => ({
            ...base,
            type: suffix,
        }));
    });

    const headersPerDevice = deviceHeaders.length;

    Object.values(data).forEach((deviceCounts) => {
        deviceHeaders.forEach((header) =>
            headers.push(
                `${deviceCounts.device.displayName} ${formatHeader(header)}`,
            ),
        );
    });

    if (Object.keys(data).length === 0) {
        return headers.join(",");
    }

    const deviceCounts = Object.values(data);

    // create a map of counts keyed by the date.
    // we can always use the last cumulative of the day
    // for reporting purposes.
    const cumulativeMap = deviceCounts.map((deviceCounts) => {
        return _.reduce(
            deviceCounts.counts,
            (acc, item) => {
                const sensorTime = moment(item.timestamp)
                    .endOf(unit)
                    .toDate()
                    .getTime();
                acc[sensorTime] = item;
                return acc;
            },
            {} as { [time: number]: Count },
        );
    });

    // reduce each day for each device down to a singular count and cumulative.
    // Only include non-imputed instant measurements.
    // If the day had no non-imputed instants, then it is a total outage.
    const deviceDailyCounts = _(deviceCounts)
        .map((device, index) => {
            const countsByDay = _.groupBy(device.counts, (instant) =>
                moment(instant.timestamp).endOf(unit).toDate().toISOString(),
            );
            return _.map(countsByDay, (instants, time) => {
                const observedInstants = instants.filter(
                    (count) => !count.imputed,
                );
                const reducedGroupedInstant = cumulativeSum(instants).at(-1)!;
                const lastGroupCumulative =
                    cumulativeMap[index][new Date(time).getTime()];
                return {
                    timestamp: moment(time).endOf(unit).toDate().toISOString(),
                    count: {
                        instant: {
                            [Direction.Upstream]: {
                                [CountType.Predicted]:
                                    reducedGroupedInstant!.cumulative!.Upstream
                                        .Predicted!,
                                [CountType.LowerBound]:
                                    reducedGroupedInstant!.cumulative!.Upstream
                                        .LowerBound!,
                                [CountType.UpperBound]:
                                    reducedGroupedInstant!.cumulative!.Upstream
                                        .UpperBound!,
                                [CountType.Adjusted]:
                                    reducedGroupedInstant!.cumulative!.Upstream
                                        .Adjusted!,
                            },
                            [Direction.Downstream]: {
                                [CountType.Predicted]:
                                    reducedGroupedInstant!.cumulative!
                                        .Downstream.Predicted!,
                                [CountType.LowerBound]:
                                    reducedGroupedInstant!.cumulative!
                                        .Downstream.LowerBound!,
                                [CountType.UpperBound]:
                                    reducedGroupedInstant!.cumulative!
                                        .Downstream.UpperBound!,
                                [CountType.Adjusted]:
                                    reducedGroupedInstant!.cumulative!
                                        .Downstream.Adjusted!,
                            },
                            [Direction.Net]: {
                                [CountType.Predicted]:
                                    reducedGroupedInstant!.cumulative!.Net
                                        .Predicted!,
                                [CountType.LowerBound]:
                                    reducedGroupedInstant!.cumulative!.Net
                                        .LowerBound!,
                                [CountType.UpperBound]:
                                    reducedGroupedInstant!.cumulative!.Net
                                        .UpperBound!,
                                [CountType.Adjusted]:
                                    reducedGroupedInstant!.cumulative!.Net
                                        .Adjusted!,
                            },
                        },
                        cumulative: {
                            [Direction.Upstream]: {
                                [CountType.Predicted]:
                                    lastGroupCumulative.cumulative!.Upstream
                                        .Predicted!,
                                [CountType.LowerBound]:
                                    lastGroupCumulative.cumulative!.Upstream
                                        .LowerBound!,
                                [CountType.UpperBound]:
                                    reducedGroupedInstant!.cumulative!.Upstream
                                        .UpperBound!,
                                [CountType.Adjusted]:
                                    reducedGroupedInstant!.cumulative!.Upstream
                                        .Adjusted!,
                            },
                            [Direction.Downstream]: {
                                [CountType.Predicted]:
                                    lastGroupCumulative.cumulative!.Downstream
                                        .Predicted!,
                                [CountType.LowerBound]:
                                    lastGroupCumulative.cumulative!.Downstream
                                        .LowerBound!,
                                [CountType.UpperBound]:
                                    lastGroupCumulative.cumulative!.Downstream
                                        .UpperBound!,
                                [CountType.Adjusted]:
                                    lastGroupCumulative.cumulative!.Downstream
                                        .Adjusted!,
                            },
                            [Direction.Net]: {
                                [CountType.Predicted]:
                                    lastGroupCumulative.cumulative!.Net
                                        .Predicted!,
                                [CountType.LowerBound]:
                                    lastGroupCumulative.cumulative!.Net
                                        .LowerBound!,
                                [CountType.UpperBound]:
                                    lastGroupCumulative.cumulative!.Net
                                        .UpperBound!,
                                [CountType.Adjusted]:
                                    lastGroupCumulative.cumulative!.Net
                                        .Adjusted!,
                            },
                        },
                    },
                    totalOutage: observedInstants.length === 0,
                };
            });
        })
        .value();

    // zip the daily counts so that the multi-dimensional array has
    // the shape [ [day-1 device 1 counts, day-2 device 2 counts, ...],
    // [day-2 device 1 counts, day-2 device 2 counts, ...], ... ]
    // as this defines the shape of the CSV.
    const dailyCounts = _.reduce(
        _.zip(...deviceDailyCounts),
        (acc, dailyDevicesCounts) => {
            if (dailyDevicesCounts && dailyDevicesCounts[0]) {
                const time = moment(dailyDevicesCounts[0].timestamp)
                    .endOf(unit)
                    .toDate()
                    .getTime();

                if (!acc[time]) {
                    acc[time] = [];
                }

                dailyDevicesCounts.forEach((count, index) => {
                    if (!acc[time][index]) {
                        acc[time][index] = {
                            instant: {
                                [Direction.Upstream]: {
                                    [CountType.Predicted]:
                                        count?.count.instant.Upstream.Predicted,
                                    [CountType.LowerBound]:
                                        count?.count.instant.Upstream
                                            .LowerBound,
                                    [CountType.UpperBound]:
                                        count?.count.instant.Upstream
                                            .UpperBound,
                                    [CountType.Adjusted]:
                                        count?.count.instant.Upstream.Adjusted,
                                },
                                [Direction.Downstream]: {
                                    [CountType.Predicted]:
                                        count?.count.instant.Downstream
                                            .Predicted,
                                    [CountType.LowerBound]:
                                        count?.count.instant.Downstream
                                            .LowerBound,
                                    [CountType.UpperBound]:
                                        count?.count.instant.Downstream
                                            .UpperBound,
                                    [CountType.Adjusted]:
                                        count?.count.instant.Downstream
                                            .Adjusted,
                                },
                                [Direction.Net]: {
                                    [CountType.Predicted]:
                                        count?.count.instant.Net.Predicted,
                                    [CountType.LowerBound]:
                                        count?.count.instant.Net.LowerBound,
                                    [CountType.UpperBound]:
                                        count?.count.instant.Net.UpperBound,
                                    [CountType.Adjusted]:
                                        count?.count.instant.Net.Adjusted,
                                },
                            },
                            cumulative: {
                                [Direction.Upstream]: {
                                    [CountType.Predicted]:
                                        count?.count.cumulative.Upstream
                                            .Predicted,
                                    [CountType.LowerBound]:
                                        count?.count.cumulative.Upstream
                                            .LowerBound,
                                    [CountType.UpperBound]:
                                        count?.count.cumulative.Upstream
                                            .UpperBound,
                                    [CountType.Adjusted]:
                                        count?.count.cumulative.Upstream
                                            .Adjusted,
                                },
                                [Direction.Downstream]: {
                                    [CountType.Predicted]:
                                        count?.count.cumulative.Downstream
                                            .Predicted,
                                    [CountType.LowerBound]:
                                        count?.count.cumulative.Downstream
                                            .LowerBound,
                                    [CountType.UpperBound]:
                                        count?.count.cumulative.Downstream
                                            .UpperBound,
                                    [CountType.Adjusted]:
                                        count?.count.cumulative.Downstream
                                            .Adjusted,
                                },
                                [Direction.Net]: {
                                    [CountType.Predicted]:
                                        count?.count.cumulative.Net.Predicted,
                                    [CountType.LowerBound]:
                                        count?.count.cumulative.Net.LowerBound,
                                    [CountType.UpperBound]:
                                        count?.count.cumulative.Net.UpperBound,
                                    [CountType.Adjusted]:
                                        count?.count.cumulative.Net.Adjusted,
                                },
                            },
                            totalOutage: count!.totalOutage,
                        };
                    }
                });
            }
            return acc;
        },
        {} as {
            [timestamp: number]: ({
                totalOutage: boolean;
            } & CountValue)[];
        },
    );

    // iterate through the dailyCounts, creating a row for each
    // entry in dailyCounts. Any records with a total outage
    // will have their values replaced with empty strings.
    const dataRows = Object.entries(dailyCounts).map(([day, counts]) => {
        // TODO: fill the following with minutes and hours. make the case that
        // we get complete consistency in CSV building rather than disaparate methods.
        // ISO8601 format for unambiguous date format. No differing formats for
        // clients between our pages.
        const row: string[] = [
            formatDate(new Date(Number(day)), timezone, unit),
        ];

        counts.forEach((count) => {
            if (!count || count.totalOutage) {
                row.push(...new Array(headersPerDevice).fill(""));
            } else {
                deviceHeaders.forEach((header) => {
                    row.push(
                        count[header.period]![header.direction][
                            header.type
                        ]!.toString(),
                    );
                });
            }
        });
        return row;
    });
    return [headers, ...dataRows].map((row) => row.join(",")).join("\n");
};
