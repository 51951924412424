import { useApiClient } from "../providers/ApiClientProvider";
import { QueryFunctionContext, useQueries } from "@tanstack/react-query";
import { Metric, PipelineMetricMap, PipelineMetrics } from "../types/Pipeline";

interface PipelineResp {
    data: {
        metrics: Metric[];
    };
}

export const useFetchPipelineMetrics = (pipelineIds: string[]) => {
    const apiClient = useApiClient();

    return useQueries({
        queries: pipelineIds.map((pipelineId) => ({
            queryKey: ["pipeline-metrics", pipelineId],
            queryFn: async ({ signal }: QueryFunctionContext) => {
                const response = await apiClient.get<PipelineResp>(
                    `/pipelines/${pipelineId}`,
                    { signal },
                );
                return response.data;
            },
            enabled: !!pipelineId,
        })),
        combine: (results) => {
            const metrics: PipelineMetrics = {};
            results.forEach((result, index) => {
                if (result.data && pipelineIds[index]) {
                    metrics[pipelineIds[index]] = result.data.metrics || [];
                }
            });

            return {
                data: new PipelineMetricMap(metrics),
                isPending: results.some((result) => result.isPending),
                isFetching: results.some((result) => result.isFetching),
                error: results.find((result) => result.error)?.error ?? null,
                isError: results.some((result) => result.error),
            };
        },
    });
};
