import { DetectionParams } from "../services/TaglessApi/TaglessApiService";

export const generateDetectionQueryString = (params: DetectionParams) => {
    const queryString = Object.entries(params)
        .filter(([_, value]) => value !== undefined)
        .map(
            ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
        )
        .join("&");
    return queryString.length > 0 ? `?${queryString}` : "";
};
