import React, { ReactElement, ReactNode, cloneElement } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";
import useDownloadFile from "../../hooks/useDownloadFile";
import moment from "moment";

interface ErrorState {
    hasError: boolean;
    message: string;
}

interface DownloadButtonBaseProps {
    /**
     * DeviceID to use for querying a file to download
     */
    deviceId: string;

    /**
     * Timestamp to use for querying a file to download
     */
    timestamp: Date;

    /**
     * Custom filename for the downloaded file
     */
    filename: string;

    /**
     * Callback function called when download fails
     */
    setErrorState: (state: ErrorState) => void;

    /**
     * Whether to render the built-in loading state
     * @default true
     */
    showLoadingState?: boolean;
}

interface DefaultButtonProps extends DownloadButtonBaseProps {
    /**
     * Custom render function for the button
     * If not provided, a default MUI Button will be used
     */
    buttonComponent?: never;

    /**
     * Text label for the button
     * @default "Download"
     */
    label?: string;

    /**
     * Text shown during download
     * @default "Downloading..."
     */
    loadingLabel?: string;

    /**
     * MUI Button variant
     * @default "contained"
     */
    variant?: "text" | "outlined" | "contained";

    /**
     * MUI Button color
     * @default "primary"
     */
    color?:
        | "inherit"
        | "primary"
        | "secondary"
        | "success"
        | "error"
        | "info"
        | "warning";

    /**
     * MUI SX props for additional styling
     */
    sx?: SxProps<Theme>;

    /**
     * Button size
     * @default "medium"
     */
    size?: "small" | "medium" | "large";
}

interface CustomButtonProps extends DownloadButtonBaseProps {
    /**
     * Custom render function for the button
     * Receives download state and handler
     */
    buttonComponent: (props: {
        onClick: () => void;
        isDownloading: boolean;
        disabled: boolean;
    }) => ReactElement;

    label?: never;
    loadingLabel?: never;
    variant?: never;
    color?: never;
    sx?: never;
    size?: never;
}

type DownloadButtonProps = DefaultButtonProps | CustomButtonProps;

/**
 * DownloadButton Component
 *
 * A flexible download button that can use either the default MUI Button
 * or a custom button component provided by a higher level component.
 */
const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
    const {
        deviceId,
        timestamp,
        filename,
        setErrorState,
        showLoadingState = true,
    } = props;

    const downloadMutation = useDownloadFile({ setErrorState: setErrorState });

    const handleDownload = () => {
        downloadMutation.mutate({
            deviceId: deviceId,
            timestamp: moment(timestamp).toISOString(),
            filename: filename,
        });
    };

    const isDownloading = downloadMutation.isPending;

    // If a custom button component is provided, use it
    if ("buttonComponent" in props && props.buttonComponent) {
        return props.buttonComponent({
            onClick: handleDownload,
            isDownloading,
            disabled: isDownloading,
        });
    }

    // Otherwise, use the default MUI Button implementation
    const {
        label = "Download",
        loadingLabel = "Downloading...",
        variant = "contained",
        color = "primary",
        sx,
        size = "medium",
    } = props as DefaultButtonProps;

    return (
        <Button
            variant={variant}
            color={color}
            disabled={isDownloading}
            onClick={handleDownload}
            startIcon={
                isDownloading || !showLoadingState ? undefined : (
                    <DownloadIcon />
                )
            }
            size={size}
            sx={{
                position: "relative",
                ...sx,
            }}
        >
            {isDownloading && showLoadingState ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress
                        size={24}
                        sx={{
                            position: "absolute",
                            left: "10px",
                            color: "inherit",
                        }}
                    />
                    <Typography variant="button" component="span">
                        {loadingLabel}
                    </Typography>
                </Box>
            ) : (
                <Typography variant="button" component="span">
                    {label}
                </Typography>
            )}
        </Button>
    );
};

export default DownloadButton;
