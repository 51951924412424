import Container from "@mui/material/Container";
import { Box, Typography } from "@mui/material";
import React from "react";
import Report from "./Components/Report";
import { useUserContext } from "../../providers/useUserProvider";

interface Error {
    hasError: boolean;
    message: string;
}

interface Props {
    setErrorState(error: Error): void;
}

const ReportLayout: React.FC<Props> = ({ setErrorState }: Props) => {
    const { selectedSite } = useUserContext();

    return (
        <Container sx={{ ml: "20%", "&:hover": { cursor: "default" } }}>
            {selectedSite ? (
                <>
                    <Typography
                        className="SiteHeader"
                        variant="h3"
                        sx={{
                            color: "#043C4A",
                            paddingTop: 3,
                            marginBottom: 0,
                        }}
                        component="div"
                    >
                        {selectedSite.subname
                            ? `${selectedSite.displayName} (${selectedSite.subname})`
                            : selectedSite.displayName}
                    </Typography>
                    <Box sx={{ paddingTop: 3 }}>
                        <Report setErrorState={setErrorState} />
                    </Box>
                </>
            ) : null}
        </Container>
    );
};

export default ReportLayout;
