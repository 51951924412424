import * as React from "react";
import "./Report.css";
import {
    Box,
    CircularProgress,
    Container,
    Skeleton,
    Tab,
    Tabs,
} from "@mui/material";
import { a11yProps, TabPanel } from "../../../components/TabPanel/TabPanel";
import { useEffect, useState } from "react";
import DailyReport from "./DailyReport";
import SummaryReport from "./SummaryReport";
import { ConfidenceIntervalSwitch } from "./Switches/ConfidenceIntervalSwitch";
import { useUserContext } from "../../../providers/useUserProvider";
import moment from "moment-timezone";

import { Device } from "../../../types/Device";
import { Count, CountType } from "../../../types/Count";

import { Deployment } from "../../../types/Deployment";
import Selector from "../../../components/Selector/Selector";
import { useFetchDeploymentsBySiteId } from "../../../hooks/useFetchDeployments";
import { useFetchRawCounts } from "../../../hooks/useFetchRawCounts";
import { DetectionParams } from "../../../services/TaglessApi/TaglessApiService";
import { useProcessedCounts } from "../../../hooks/useProcessedCounts";
import { useFetchPipelineMetrics } from "../../../hooks/useFetchPipelineMetrics";
import { useFetchDevice } from "../../../hooks/useFetchDevice";

export interface DeviceCounts {
    [deviceId: string]: {
        device: Device;
        counts: Count[];
    };
}

interface ErrorState {
    hasError: boolean;
    message: string;
}

const CI_TOGGLE_KEY = "CI_SWITCH_ENABLED";

const Report = ({
    setErrorState,
}: {
    setErrorState: (state: ErrorState) => void;
}) => {
    // Site
    const { selectedSite, timezone } = useUserContext();

    // Page controls
    const [activeTab, setActiveTab] = useState(0);
    const [ciSwitchEnabled, setCISwitchEnabled] = useState(false);
    const [ciSwitchChecked, setCISwitchChecked] = useState(() => {
        return localStorage.getItem(CI_TOGGLE_KEY) === "true";
    });

    const initialLoadDone = React.useRef(false);

    // Deployments
    const [selectedDeployment, setSelectedDeployment] =
        useState<Deployment | null>(null);

    const { deployments, isLoading: deploymentsLoading } =
        useFetchDeploymentsBySiteId(selectedSite!.id);

    const sortedDeployments = React.useMemo(
        () =>
            deployments
                ? [...deployments].sort(
                      (a, b) =>
                          new Date(b.created).getTime() -
                          new Date(a.created).getTime(),
                  )
                : [],
        [deployments],
    );

    const { data: devices, isLoading: devicesLoading } = useFetchDevice(
        selectedSite,
        selectedDeployment,
    );

    useEffect(() => {
        setSelectedDeployment(null);
    }, [selectedSite?.id]);

    // Load set the initial deployment. SortedDeployment
    // should not change unless there is a new site selected.
    useEffect(() => {
        const deployment: Deployment = selectedDeployment
            ? selectedDeployment
            : sortedDeployments[0];
        if (!selectedDeployment) setSelectedDeployment(deployment);
    }, [selectedDeployment, sortedDeployments]);

    // create refs for use on the first load to fetch counts.
    const storageKey = `deployments_${selectedSite!.id}`;

    // If deployments are loaded, see if there exists a preferred
    // deployment for the site.
    useEffect(() => {
        if (!deploymentsLoading) {
            const preferredSelection = localStorage.getItem(storageKey);
            const selectedDeployment = preferredSelection
                ? deployments.find((d) => d.displayName === preferredSelection)
                : sortedDeployments[0];
            if (selectedDeployment) {
                localStorage.setItem(
                    storageKey,
                    selectedDeployment.displayName,
                );
                setSelectedDeployment(selectedDeployment ?? null);
            }
        }
    }, [
        sortedDeployments,
        deploymentsLoading,
        selectedSite,
        deployments,
        storageKey,
    ]);

    const detectionParams: DetectionParams = React.useMemo(() => {
        if (!selectedDeployment || !timezone) {
            return {
                startTimestamp: undefined,
                endTimestamp: undefined,
                interval: undefined,
            };
        }

        return {
            startTimestamp: moment(selectedDeployment.startTime)
                .tz(timezone)
                .startOf("day")
                .toISOString(),
            endTimestamp: moment(selectedDeployment.endTime)
                .tz(timezone)
                .endOf("day")
                .toISOString(),
            interval: "1h",
        };
    }, [selectedDeployment, timezone]);

    // Counts
    const {
        data: rawCountArray,
        isPending: isCountPending,
        isError,
        error,
    } = useFetchRawCounts(
        devices || [],
        detectionParams,
        selectedDeployment?.id,
    );

    if (isError) {
        setErrorState({
            hasError: true,
            message: error?.message || `unable to fetch count`,
        });
    }

    const allDetections = rawCountArray.flat();

    const pipelineIds = Array.from(
        new Set(allDetections.map((d) => d.pipelineID)),
    );

    const pipelineMetricResult = useFetchPipelineMetrics(pipelineIds);

    if (pipelineMetricResult.isError) {
        setErrorState({
            hasError: true,
            message:
                pipelineMetricResult.error?.message ||
                `unable to fetch pipeline`,
        });
    }

    const { counts, ciEnabled } = useProcessedCounts(
        devices || [],
        rawCountArray,
        pipelineMetricResult.data,
        selectedDeployment,
        timezone,
    );

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        if (typeof ciEnabled === "boolean") {
            setCISwitchEnabled(ciEnabled);
            if (!ciEnabled) {
                setCISwitchChecked(false);
            }
        }
        if (ciSwitchEnabled) {
            const savedPreference =
                localStorage.getItem(CI_TOGGLE_KEY) === "true";
            setCISwitchChecked(savedPreference);
            initialLoadDone.current = true;
        }
    }, [ciEnabled, counts]);

    const handleDeploymentSelect = (deployment: Deployment | null) => {
        // Update the selected deployment
        if (deployment) {
            setSelectedDeployment(deployment);
            localStorage.setItem(storageKey, deployment.displayName);
        }
    };

    const handleCISwitchChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        localStorage.setItem(CI_TOGGLE_KEY, `${event.target.checked}`);
        setCISwitchChecked(event.target.checked);
    };

    const isLoading =
        deploymentsLoading ||
        devicesLoading ||
        isCountPending ||
        pipelineMetricResult.isPending ||
        !counts;

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ my: 2 }}>
                        <Selector<Deployment>
                            options={sortedDeployments}
                            selectedItem={selectedDeployment}
                            onSelect={handleDeploymentSelect}
                            isLoading={deploymentsLoading || isCountPending}
                            getOptionLabel={(d) => d.displayName}
                            label={"Season"}
                        />
                    </Box>
                </Container>
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label="report tabs"
                    >
                        <Tab label="Summary" {...a11yProps(0)} />
                        <Tab label="Daily Report" {...a11yProps(1)} />
                    </Tabs>

                    {ciEnabled !== undefined ? (
                        activeTab === 0 && (
                            <ConfidenceIntervalSwitch
                                checked={ciSwitchChecked}
                                onChange={handleCISwitchChange}
                                disabled={!ciSwitchEnabled || isCountPending}
                                deploymentSeason={
                                    selectedDeployment
                                        ? selectedDeployment.displayName
                                        : ""
                                }
                            />
                        )
                    ) : (
                        <Skeleton variant="rectangular" />
                    )}
                </Container>
            </Box>
            {!isLoading ? (
                <React.Fragment>
                    <TabPanel value={activeTab} index={0}>
                        <SummaryReport
                            data={counts}
                            reportedCountType={
                                ciSwitchChecked
                                    ? CountType.Adjusted
                                    : CountType.Predicted
                            }
                        />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <DailyReport
                            deviceCounts={counts}
                            devices={devices}
                            setErrorState={setErrorState}
                            deployment={selectedDeployment}
                        />
                    </TabPanel>
                </React.Fragment>
            ) : (
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        paddingTop: "5%",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </Container>
            )}
        </Box>
    );
};

export default Report;
