import { createContext, useContext, PropsWithChildren } from "react";

import { apiClient } from "../services/Services";

const ApiClientContext = createContext<typeof apiClient | null>(null);

export const useApiClient = () => {
    const context = useContext(ApiClientContext);

    if (!context) {
        throw new Error("useAppConfig must be used within AppConfigProvider");
    }
    return context;
};

export const ApiClientProvider = ({ children }: PropsWithChildren) => {
    return (
        <ApiClientContext.Provider value={apiClient}>
            {children}
        </ApiClientContext.Provider>
    );
};
