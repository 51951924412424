import { generateDeploymentKey } from "../react-query/keyFactory";
import { useApiQuery } from "./useApiQuery";
import { Deployment } from "../types/Deployment";

export const useFetchDeploymentsBySiteId = (siteId: string) => {
    const queryKey = generateDeploymentKey(siteId);
    const { data: deployments = [], ...queryResult } = useApiQuery<
        Deployment[]
    >({
        queryKey,
        endpoint: `sites/${siteId}/deployments`,
        enabled: !!siteId,
    });

    return { deployments, ...queryResult };
};
